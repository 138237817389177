import './college_info_v3.scss'
import '../../../components/html/common/common'
import '../../../components/html/common_buttons/common_buttons'
import '../../../components/html/slick_slider/slick_slider'

const collegeTabs = Array.from(document.querySelectorAll('.clients-item-wrapper'));
const sliderLeft = document.querySelector('.partnering_slide_arrows_left');
const sliderRight = document.querySelector('.partnering_slide_arrows_right');
const firstSlides = Array.from(document.querySelectorAll('.clients-item-wrapper[data-slide="1"]'));
const secondSlides = Array.from(document.querySelectorAll('.clients-item-wrapper[data-slide="2"]'));
const toTopBtn = document.querySelector('.arrow-container');
const changeCard = Array.from(document.querySelectorAll('.change-card'));

changeCard.forEach((card) => {
  card.addEventListener('mouseover', inCardHover, false);
  card.addEventListener('mouseout', outCardHover, false);
})

function inCardHover() {
  this.querySelector('.change-card-text').style.cssText = 'height: 6.7em; white-space: normal; transition: height, 1s, easy;';
}
function outCardHover() {
  this.querySelector('.change-card-text').style.cssText = 'height: 1.2em; white-space: nowrap; transition: height, 1s, easy;';
}

collegeTabs.forEach((tab) => {
  tab.addEventListener('click', (event) => {
    let collegeTabsActive = document.querySelector('.clients-item-wrapper.active');
    let current_tile = event.target.closest('.clients-item-wrapper');
    current_tile.classList.add('active');
    collegeTabsActive.classList.remove('active');
    changeQuote(current_tile.dataset.tab);
    disableArrows();
  });
})

clickSlider();
function clickSlider() {
  arrowClick(sliderLeft);
  arrowClick(sliderRight);
}

function arrowClick(arrow) {
  arrow.addEventListener('click', (event) => {
    const prevPosition = resetActiveTab();
    const newPosition = stringifyTabAttr(event.target.className, prevPosition);
    disableArrows();
  })
}

function resetActiveTab() {
  let collegeTabsActive = document.querySelector('.clients-item-wrapper.active');
}

function stringifyTabAttr(className, position) {
  if (className === 'slide-arrows_left') {
    +position--;
    if (position === 3) switchSlide(firstSlides, secondSlides);
  } else {
    +position++;
    if (position === 4) switchSlide(secondSlides, firstSlides);
  }

  return position.toString();
}

function switchSlide(currentSlides, nextSlides) {
  currentSlides.forEach((currentSlide) => {
    currentSlide.style.display = 'block';
    nextSlides.forEach((nextSlide) => {
      nextSlide.style.display = 'none';
    })
  })
}

disableArrows();
function disableArrows() {
  disableArrow(sliderLeft, 0)
  disableArrow(sliderRight, 7)
}

function disableArrow(selector, index) {
  if (document.querySelector(`.clients-item-wrapper.active[data-tab="${index}"]`)) {
    selector.style.cssText = 'pointer-events: none; filter: grayscale(0.8);';
  } else {
    selector.style.cssText = 'pointer-events: auto; filter: grayscale(0);';
  }
}

window.addEventListener("scroll", (event) => {
  let scroll = window.scrollY;
  let bodyHeight = document.body.scrollHeight;
  let header = document.querySelector('.navbar').offsetHeight;
  let footerHeight = document.querySelector('.footer').offsetHeight;
  let footerScrumlaunch = document.querySelector('.footer__scrumlaunch').offsetHeight;
  let arrowHeight = bodyHeight - (footerHeight + footerScrumlaunch + header*3);
  if (scroll < 500 || scroll > arrowHeight) {
    toTopBtn.style.display = 'none';
  } else {
    toTopBtn.style.display = 'block';
  }
});

toTopBtn.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
})
$('.partnering_menu').not('.slick-initialized').slick({
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 3,
  autoplay: true,
  speed: 200,
  dots: false,
  rows: 0,
  prevArrow: $('.partnering_slide_arrows_left'),
  nextArrow: $('.partnering_slide_arrows_right'),
  responsive: [
    {
      breakpoint: 1181,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 944,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 868,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false
      }
    },
    {
      breakpoint: 468,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
});

let pinkBtn = document.querySelectorAll('.pink-btn');
let emailInput = document.querySelectorAll('.college_recruitment_email_input');
let hiddenInput = document.querySelector('.hidden_input');
let emailField = document.querySelectorAll('.email_check');

emailInput.forEach(input => {
  input.addEventListener('input',() => {
    emailInput.forEach(element => {
      element.value = input.value;
    })
    hiddenInput.value = input.value;
  })
})

function isEmailValid(emailText) {
  var reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(emailText)) {
    console.log(reg.test(emailText))
    emailField.forEach(el=>el.innerHTML = "<p class='valid_email'>email sent</div>")
    sendEmail();
    return;
  } else {
    console.log(reg.test(emailText))
    emailField.forEach(el=>el.innerHTML = "<p class='invalid_email'>email not valid</div>")
  }
}
pinkBtn.forEach(el => {
  el.addEventListener('click',() => {
    if (!hiddenInput.value) {
      return;
    } else {
      isEmailValid(hiddenInput.value)
      emailInput.forEach(input => {
        input.value = '';
      })
      hiddenInput.value = '';
    }
  })
})
async function sendEmail() {
  let url = `/college_recruitment_email?email=${hiddenInput.value}&url=${window.location.href}`;
  if (hiddenInput.value) {
    try {
      const send = await fetch(url, {
        method: 'GET',
      });
    } catch (error) {
      emailField.forEach(el=>el.innerHTML = "<p class='error_email'>something went wrong</div>")
      console.log(error)
    }
  }
}
